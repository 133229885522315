@import '~antd/dist/antd.css';

.App {
	text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.BoxTransition {
	transition: all 0.25s ease;
}

/* Force the AntD spinner to be centered vertically */
.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
  height: calc(100% - 192px);
}